export const channelList = [
  { label: '全部', value: 'all' },
  { label: 'DHL', value: 1 },
  { label: '联邦', value: 2 },
  { label: 'UPS', value: 3 },
  { label: '美国海派包税 普通快船', value: 4 },
  { label: '美国海派 海森快船', value: 5 },
  { label: '德国空派', value: 6 },
  { label: '英国空派', value: 7 },
  { label: '美国空派', value: 8 },
  { label: '德国海运', value: 9 }
]
